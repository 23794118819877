import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import bg from '../images/offer-bg.webp'
import { useMediaQuery } from 'react-responsive';
import { setToggleTrue } from '../redux/slice/changeState'
import { useDispatch } from 'react-redux'

const Offer = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 991px)' });
    const isSmallMobile = useMediaQuery({ query: '(max-width: 425px)' });
    const isSmallLaptop = useMediaQuery({query:'((min-width: 991px) and (max-width: 1080px)'})
    const dispatch = useDispatch()
    return (
        <div className='offer-section section-padding position-relative' style={{ backgroundImage: `url(${bg})` }}>
            <Container>
                <Row>
                    <Col lg={7}>
                        <div className='position-relative'>
                            {isMobile ?
                                <>
                                <h2 className={`${isMobile ? 'mb-2' : 'mb-4'}`}>Transparent Pricing with Special Offers</h2>
                                <h6 className='text-capitalize'>Own a premium <span>3 BHK</span> apartment at competitive rates, starting at <span>₹5,999/sq. ft</span>.</h6>
                                </>
                                :
                                <>
                                    <h2 >Transparent Pricing</h2>
                                    <h2 className={`${isMobile ? 'mb-2' : 'mb-4'}`}> with Special Offers</h2>
                                    {isSmallLaptop ? 
                                    <h6 className='text-capitalize'>Own a premium <span>3 BHK</span> apartment at competitive rates, starting at <span>₹5,999/sq. ft</span>.</h6>
                                    :
                                    <>
                                    <h6 className='text-capitalize'>Own a premium <span>3 BHK</span> apartment at competitive rates, </h6>
                                    <h6 className='text-capitalize'>starting at <span>₹5,999/sq. ft</span>.</h6>
                                    </>
                                    }
                                    

                                </>
                            }


                            
                            {/* <h4 className={`${isMobile ? 'mt-4' : 'mt-50'}`}>Year of IRA 2025 Offer :</h4>
                            <div className='offer-coupan'>
                                {
                                    isMobile ?
                                        isSmallMobile ?
                                        <>
                                            <p>Get a <span>₹8,88,888 FREE</span> furnishing coupon</p>
                                            <p>when you book before <span>December 31st, 2024.</span></p>
                                        </>
                                        :
                                        <p>Get a <span>₹8,88,888 FREE</span> furnishing coupon when you book before <span>December 31st, 2024.</span></p>
                                        :
                                        <>
                                            <p>Get a <span>₹8,88,888 FREE</span> furnishing coupon</p>
                                            <p>when you book before <span>December 31st, 2024.</span></p>
                                        </>
                                }

                            </div> */}
                            <button className={`common-btn primary-background text-white ${isMobile ? 'mt-4' : 'mt-50'}`} onClick={() => dispatch(setToggleTrue())}>Claim Your Special Offer</button>
                        </div>
                    </Col>
                    <Col lg={5}>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Offer
