import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
// import required modules
import { Pagination } from 'swiper/modules';
import img1 from '../images/user1.webp'
import img2 from '../images/user2.webp'
import img3 from '../images/user3.webp'
import { Container, Card } from 'react-bootstrap';
import { FaStar } from "react-icons/fa";
import { useMediaQuery } from 'react-responsive'


const Testimonial = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 991px)' });
  const isSmallMobile = useMediaQuery({ query: '(max-width: 550px)' });
  const data = [
    {
      "rating": 5,
      "discrip": "IRA Realty has delivered more than just a home – they’ve created a community where we feel safe and comfortable.",
      "img": img1,
      "name": "Srinivas Reddy N",
      "location": "Hyderabad, Telangana"
    },
    {
      "rating": 5,
      "discrip": "The amenities and location make IRA Miracle the perfect place for our family. We couldn’t be happier with our decision.",
      "img": img2,
      "name": "Pooja Singh",
      "location": "Hyderabad, Telangana"
    },
    {
      "rating": 5,
      "discrip": "IRA Realty has delivered more than just a home – they’ve created a community where we feel safe and comfortable",
      "img": img3,
      "name": "Narayan Rao B",
      "location": "Hyderabad, Telangana"
    }
  ]
  return (
    <div className={`testimony-section position-relative`}>
      <Container>
        {isSmallMobile ?
          <>
            <h2>What Our Happy</h2>
            <h2 className='mb-20'>Residents Say</h2>
          </>
          :
          <h2 className='mb-50'>What Our Happy Residents Say</h2>
        }

        <Swiper
          slidesPerView={2}
          spaceBetween={10}
          pagination={{ clickable: true }}
          modules={[Pagination]}
          breakpoints={{
            640: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 40,
            },
          }}
          className="mySwiper">


          {data.map((ele, i) => {
            return (
              <SwiperSlide key={i}>
                <Card>
                  <Card.Body>
                    <Card.Title>
                      <div className='rating-star mb-2'>
                        {Array.from({ length: ele.rating }).map((_, ind) => (
                          <FaStar key={ind} />
                        ))}

                      </div>
                    </Card.Title>
                    <div className={`${isMobile ? 'mt-2' : 'mt-5'}`}>
                      <div className='user-content'>
                        <div className='user-discription'>
                          <p>{ele.discrip}</p>
                        </div>
                        <div className='user-data d-flex'>
                          {/* <img src={ele.img} alt={ele.img} className='img-fluid'></img> */}

                          <div>
                            <p>{ele.name}</p>
                            <p>{ele.location}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </SwiperSlide>
            )
          })}

        </Swiper>
        {/* <button className='common-btn primary-background text-white'>Read More Testimonials</button> */}
      </Container>
    </div>
  )
}

export default Testimonial
